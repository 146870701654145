import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { motion, MotionStyle } from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import { adminMenu, crmMenu, platformMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import User from '../User/User';
import { useSelector } from 'react-redux/es/exports';
import { RootState } from 'typesafe-actions';
import Icon from '../../components/icon/Icon';
import { useDispatch } from 'react-redux';
import AuthContext from '../../contexts/authContext';
import showNotification from '../../components/extras/showNotification';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { firmId } = useSelector((state: RootState) => state.user.firmInfo);
	const { userId } = useSelector((state: RootState) => state.user.userInfo);
	const { platformAuthority, platform } = useSelector((state: RootState) => state.user);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const { setUser } = useContext(AuthContext);

	const dispatch = useDispatch();

	function handleChangePlatform(val: string) {
		if (setUser) {
			const local = JSON.parse(localStorage.getItem('ramate_crm_auth') || '{}');
			local.platform = val;
			setUser(JSON.stringify(local));
		}
		dispatch({ type: 'FIRM_CHANGE_PLATFORM', payload: val });
		setTimeout(() => {
			showNotification('切換平台', '切換平台成功，正在重整資料', 'info');
		}, 300);
	}

	return (
		<>
			<motion.aside
				style={asideStyle as MotionStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>

				<div className='aside-body'>
					{/* <Navigation menu={dashboardMenu} id='aside-dashboard' /> */}
					{/* <Navigation menu={demoPages} id='aside-demo-pages2' /> */}

					{platformAuthority.bombcourse && platformAuthority.bombmy && (
						<ul className='navigation'>
							<div style={{ marginLeft: '20px', fontSize: '10px' }}>平台</div>
							<li className='navigation-item' onClick={() => handleChangePlatform('0')}>
								<div className={`navigation-link navigation-link-pill cursor-pointer ${platform === '0' && 'active'}`}>
									<span className='navigation-link-info'>
										<Icon className='navigation-icon' icon='Filter1' />
										<span className='navigation-text'>幫賣 {platform === '0' && '(當前)'}</span>
									</span>
								</div>
							</li>
							<li className='navigation-item' onClick={() => handleChangePlatform('1')}>
								<div className={`navigation-link navigation-link-pill cursor-pointer ${platform === '1' && 'active'}`}>
									<span className='navigation-link-info'>
										<Icon className='navigation-icon' icon='Filter2' />
										<span className='navigation-text'>幫開課 {platform === '1' && '(當前)'}</span>
									</span>
								</div>
							</li>
							<NavigationLine />
						</ul>
					)}

					{firmId !== '' && userId !== '' ? (
						<Navigation menu={crmMenu} id='aside-dashboard' />
					) : firmId === '' && userId !== '' ? (
						<Navigation menu={adminMenu} id='admin-menu' />
					) : null}
				</div>

				<div className='aside-foot'>
					{/* <nav aria-label='aside-bottom-menu'></nav> */}
					{userId !== '' ? <User /> : null}
				</div>
				<div className='aside-foot'></div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
