export const defaultLoveCode = [
	{
		orgName: '社團法人台灣原鄉智慧農業科技發展協會',
		loveCode: '1106',
	},
	{
		orgName: '財團法人新竹市杜華神父社會福利基金會',
		loveCode: '373',
	},
	{
		orgName: '社團法人世界和平婦女會台灣總會',
		loveCode: '123',
	},
	{
		orgName: '中華ＩＰＯＷＥＲ關懷協會',
		loveCode: '7313',
	},
	{
		orgName: '社團法人中華仁親社區關懷協會',
		loveCode: '8958',
	},
	{
		orgName: '中華修緣世界協會',
		loveCode: '50637',
	},
	{
		orgName: '社團法人中華傳愛社區服務協會',
		loveCode: '085',
	},
	{
		orgName: '中華分享愛大善急助協會',
		loveCode: '8234',
	},
	{
		orgName: '社團法人中華創新發明學會',
		loveCode: '0000000',
	},
	{
		orgName: '中華動物希望協會',
		loveCode: '520505',
	},
	{
		orgName: '社團法人中華勵本志身心障礙者技藝創業協會',
		loveCode: '708585',
	},
	{
		orgName: '中華同舟共濟服務社',
		loveCode: '1970',
	},
	{
		orgName: '社團法人中華善德關懷協會',
		loveCode: '1100919',
	},
	{
		orgName: '社團法人中華基督徒地方教會聯合差傳協會',
		loveCode: '17268',
	},
	{
		orgName: '社團法人中華基督教以琳關懷協會',
		loveCode: '5210',
	},
	{
		orgName: '社團法人中華基督教聯合差傳事工促進會',
		loveCode: '924',
	},
	{
		orgName: '中華大家功德會',
		loveCode: '999999',
	},
	{
		orgName: '中華好家庭關懷協會',
		loveCode: '377168',
	},
	{
		orgName: '中華家庭教育與權益促進協會',
		loveCode: '972',
	},
	{
		orgName: '社團法人中華家庭暨社區展望協會',
		loveCode: '9949',
	},
	{
		orgName: '中華小馬少年籃球運動協會',
		loveCode: '888888',
	},
	{
		orgName: '中華弘慈協會',
		loveCode: '123123',
	},
	{
		orgName: '社團法人中華扶智生命教育協會',
		loveCode: '52374',
	},
	{
		orgName: '中華救助總會',
		loveCode: '5943',
	},
	{
		orgName: '中華數位人文關懷協會',
		loveCode: '1314520',
	},
	{
		orgName: '中華日月門黃帝內經學會',
		loveCode: '795',
	},
	{
		orgName: '中華有保佑國際宗教文化交流協會',
		loveCode: '58588',
	},
	{
		orgName: '中華武學人文推廣協會',
		loveCode: '5050520',
	},
	{
		orgName: '中華民國世界母親協會',
		loveCode: '685591',
	},
	{
		orgName: '社團法人中華民國亞杜蘭關懷協會',
		loveCode: '106',
	},
	{
		orgName: '中華民國健康心靈關懷協會',
		loveCode: '9580',
	},
];
