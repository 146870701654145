import React from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import NoPermissionImg from '../../../assets/resources/images/noPermisson.webp';
import Button from '../../../components/bootstrap/Button';

const NoPermission = () => {
	return (
		<PageWrapper title='權限不足'>
			<Page>
				<div className='row d-flex align-items-center h-100'>
					{/* <div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div className='text-primary fw-bold' style={{ fontSize: 'calc(3rem + 3vw)' }}>
							No Permission
						</div>
						<div className='text-dark fw-bold' style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
							權限不足
						</div>
					</div> */}
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<img srcSet={NoPermissionImg} src={NoPermissionImg} alt='Humans' style={{ height: '50vh' }} />
					</div>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button className='px-5 py-3' color='primary' isLight icon='HolidayVillage' tag='a' href='/auth-pages/login'>
							回登入
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default NoPermission;
