import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Select from '../../components/bootstrap/forms/Select';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../components/bootstrap/Modal';
import Page from '../../layout/Page/Page';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import Card, { CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Textarea from '../../components/bootstrap/forms/Textarea';
import Option from '../../components/bootstrap/Option';
import Timeline, { TimelineItem } from '../../components/extras/Timeline';
import useDarkMode from '../../hooks/useDarkMode';
import useSWR, { mutate } from 'swr';
import { ErrorProp } from '../../types/response';
import { INVOICE_CARRIER_TYPE, INVOICE_STATUS, INVOICE_TYPE, PAYMENT_METHOD } from '../../common/status/status';
import {
	atmPaidNotify,
	changeFrontPaymentMethod,
	checkNotice,
	createOrderLogCustomer,
	frontCreatePayment,
	frontUpdateInvoiceInfo,
	getFirmInvoiceMethod,
	getFirmPayMethod,
	getFrontOrder,
	triggerGtm,
} from '../../api/front';
import { PurchaseGetFirmPayMethodRes, PurchaseGetOrderInfoRes } from '../../swagger/thirdParty';
import { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import LinkText from '../../components/LinkText';
import Spinner from '../../components/bootstrap/Spinner';
import Popovers from '../../components/bootstrap/Popovers';
import moment from 'moment';
import { parsePrice } from '../../helpers/helpers';
import { defaultLoveCode } from '../../common/data/loveCode';
import { orderCustomerMessageNoticeLetter } from '../../api/ramateVerify';
import Linkify from 'react-linkify';
import { ReactTagManager } from 'react-gtm-ts';

declare global {
	interface Window {
		gtag?: (...args: any[]) => void;
	}
}

const FrontendOrderPage = () => {
	const [showPaymentModal, setShowPaymentModal] = useState(false);
	const [issueInvoiceModal, setIssueInvoiceModal] = useState(false);
	const [atmPaidNotifyModal, setAtmPaidNotifyModal] = useState(false);
	const [paymentForm, setPaymentForm] = useState('');
	const [payClicked, setPayClicked] = useState(false);
	const [screenCheck, setScreenCheck] = useState(false);
	const [isFirstLoading, setIsFirstLoading] = useState(true);
	const [isGtag, setIsGtag] = useState(false);

	const handleRWD = () => {
		if (window.innerWidth > 768) setScreenCheck(true);
		else setScreenCheck(false);
	};

	useEffect(() => {
		window.addEventListener('resize', handleRWD);
		handleRWD();

		return () => {
			window.removeEventListener('resize', handleRWD);
		};
	}, []);

	const { orderId, auto } = useParams();
	const {
		data: orderDetails,
		// data: orderDetails = {
		// 	orderId: '',
		// 	orderNo: '',
		// 	paymentInfo: {
		// 		paymentMethod: '',
		// 		paymentDate: '',
		// 		paymentAmount: 0,
		// 		paymentStatus: '',
		// 		bankCode: '',
		// 		bankName: '',
		// 		vAccount: '',
		// 		expireDate: '',
		// 		realAccount: '',
		// 		accountLastFiveCode: '',
		// 		bankBranchName: '',
		// 		bankAccountName: '',
		// 		refundAmt: 0,
		// 		refundText: '',
		// 		getAccountDate: '',
		// 	},
		// 	orderDetail: [],
		// 	invoiceInfo: {
		// 		invoiceNo: '',
		// 		invoiceType: '',
		// 		invoiceCarrierType: '',
		// 		invoiceCarrierInfo: '',
		// 		invoiceIdentifier: '',
		// 		invoiceTitle: '',
		// 		loveCode: '',
		// 		invoiceCustomerName: '',
		// 		invoiceIssueType: '',
		// 		invoiceStatus: '',
		// 		lockInvoiceStatus: false,
		// 	},
		// 	deliveryInfo: {
		// 		name: '',
		// 		phone: '',
		// 		email: '',
		// 		address: '',
		// 		countryCode: '',
		// 		sevenStoreId: '',
		// 		sevenStoreName: '',
		// 		sevenStoreAddress: '',
		// 	},
		// 	orderSubtotal: 0,
		// 	cashFee: 0,
		// 	deliveryFee: 0,
		// 	orderTotal: 0,
		// 	orderStatus: '',
		// 	deliveryType: '',
		// 	orderMemoCustomer: '',
		// 	virtualLogisticsInfo: [],
		// 	entityTrackLogisticsInfo: [],
		// 	platformType: '',
		// 	orderUserId: '',
		// 	orderLogInfo: [],
		// 	firmId: '',
		// 	signupId: '',
		// 	orderDescription: '',
		// 	noticeDescription: '',
		// 	noticeName: '',
		// 	noticeValue: '',
		// 	gtmTrigger: 0,
		// 	gtmList: [],
		// 	utmCampaign: '',
		// 	utmContent: '',
		// 	utmSource: '',
		// 	utmMedium: '',
		// },
	} = useSWR<PurchaseGetOrderInfoRes, ErrorProp>(`/f-order/${orderId}`, () => getFrontOrder(orderId || ''), {
		onSuccess: (data) => {
			if (!isFirstLoading) return;

			setIsFirstLoading(false);
			if (data.gtmList && data?.gtmList?.length > 0) {
				data.gtmList.forEach((e) => {
					if (e.gtmId) {
						ReactTagManager.init({
							code: e.gtmId, // GTM Code
							debug: false, // debug mode (default false)
							performance: false, // starts GTM only after user interaction (improve initial page load)
						});
					}

					if (e.gtag) {
						let gtag = document.createElement('script');
						gtag.src = `https://www.googletagmanager.com/gtag/js?id=${e.gtag}`;
						gtag.async = true;
						gtag.id = `ga-gtag`;
						document.head.appendChild(gtag);
						let gt = document.createElement('script');
						gt.innerHTML = `
					window.dataLayer = window.dataLayer || [];
					function gtag(){dataLayer.push(arguments);}
					gtag('js', new Date());
					gtag('config', '${e.gtag}');
					`;
						document.head.appendChild(gt);
						setIsGtag(true);
					}
				});
			}
		},
	});

	const { data: avaliablePaymentMethod } = useSWR<PurchaseGetFirmPayMethodRes, ErrorProp>(
		orderDetails?.firmId ? `/f-order/${orderId}/firmPayMethod` : null,
		() => getFirmPayMethod(orderDetails?.firmId || '', orderDetails?.signupId || '', orderDetails?.platformType || ''),
		{
			errorRetryInterval: 1000,
		},
	);

	const {
		data: avaliableInvoiceMethod = {
			typeDonation: false,
			typeMember: false,
			typeCertificate: false,
			typeBarcode: false,
			typeCompany: false,
			invoicePaper: false,
			invoiceThirdParty: false,
		},
	} = useSWR(orderDetails?.firmId && `/f-order/${orderId}/firmInvoiceMethod`, () => getFirmInvoiceMethod(orderDetails?.firmId || ''), {
		errorRetryInterval: 1000,
	});

	const [changePaymentMethodModal, setChangePaymentMethodModal] = useState(false);

	useEffect(() => {
		if (orderDetails?.orderDetail && auto && !payClicked) {
			handleClickPay();
			setPayClicked(true);
		}

		if (orderDetails && !orderDetails?.gtmTrigger && (orderDetails?.paymentInfo?.paymentStatus === '已付款' || orderDetails?.orderStatus === '已完成')) {
			if (isGtag && orderDetails?.orderDetail) {
				const orderProducts = orderDetails?.orderDetail.reduce((map, current) => {
					if (window?.gtag) {
						window.gtag('event', '付款完成', {
							orderId: orderId,
							utm_source: orderDetails?.utmSource || '',
							utm_medium: orderDetails?.utmMedium || '',
							utm_campaign: orderDetails?.utmCampaign || '',
							utm_content: orderDetails?.utmContent || '',
							item_name: current.productName || '',
							price: current.productPrice || '',
							quantity: current.productQuantity || '',
						});
					}
					return map;
				}, []);

				handleTriggerGtm();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderDetails, isGtag]);

	const formik = useFormik({
		initialValues: {
			paymentMethod: orderDetails?.paymentInfo?.paymentMethod || '0',
		},
		onSubmit: async (values) => {
			// console.log(values);
			const { paymentMethod } = values;
			if (!paymentMethod || !orderId || !orderDetails?.orderUserId || !orderDetails?.platformType) return;

			changeFrontPaymentMethod(orderId, paymentMethod, orderDetails?.orderUserId, orderDetails?.platformType)
				.then(() => {
					mutate('/f-order/' + orderId);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>儲存成功!</span>
						</span>,
						`付款方式已更新`,
					);
					setChangePaymentMethodModal(false);
				})
				.catch((err) => {
					showNotification('錯誤', err.error.message, 'danger');
				});
		},
	});

	const issueInvoiceFormik = useFormik({
		initialValues: {
			invoiceIdentifier: '',
			invoiceCustomerName: '',
			invoiceCarrierInfo: '',
			invoiceCarrierType: '',
			invoiceIssueType: '1',
			invoiceTitle: '',
			invoiceType: '0',
			loveCode: '',
			orderId: '',
			updateUser: '',
			firmId: orderDetails?.firmId,
			createLogUser: '1',
		},
		onSubmit: async (values) => {
			if (!orderDetails || !orderDetails?.orderUserId || !orderId) return;
			values.updateUser = orderDetails.orderUserId;
			values.orderId = orderId;

			frontUpdateInvoiceInfo(values)
				.then(() => {
					mutate('/f-order/' + orderId);
					setIssueInvoiceModal(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>編輯待開立發票資料成功!</span>
						</span>,
						`發票資料已更新`,
						'success',
					);
				})
				.catch((err) => {
					showNotification('編輯待開立發票資料發生錯誤', err.error.message, 'danger');
				});
		},
	});

	const atmPaidNotifyFormik = useFormik({
		initialValues: {
			orderId: '',
			paymentDate: '',
			accountLastFiveCode: '',
			firmId: '',
		},
		validate: (values) => {
			const errors: {
				paymentDate?: string;
				accountLastFiveCode?: string;
			} = {};
			if (values.accountLastFiveCode === '') {
				errors.accountLastFiveCode = '請輸入帳號後五碼';
			} else if (values.accountLastFiveCode.length !== 5) {
				errors.accountLastFiveCode = '請輸入完整的帳號後五碼';
			}

			if (!values.paymentDate) {
				errors.paymentDate = '請輸入付款時間';
			} else {
				try {
					values.paymentDate = moment(values.paymentDate).format('YYYY-MM-DD HH:mm:ss');
				} catch (error) {
					errors.paymentDate = '請輸入完整的付款時間';
				}
			}

			return errors;
		},
		onSubmit: async (values) => {
			if (!orderDetails || !orderDetails?.firmId || !orderId) return;
			values.orderId = orderId;
			values.firmId = orderDetails.firmId;
			values.paymentDate = moment(values.paymentDate).format('YYYY-MM-DD HH:mm:ss');
			// console.log(values);
			atmPaidNotify(values)
				.then(() => {
					mutate('/f-order/' + orderId);
					setAtmPaidNotifyModal(false);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>通知商家成功!</span>
						</span>,
						``,
						'success',
					);
				})
				.catch((err) => {
					showNotification('通知商家發生錯誤！', err.error.message, 'danger');
				});
		},
	});

	const messageFormik = useFormik({
		initialValues: {
			customerMessage: '',
		},
		onSubmit: async (values) => {
			if (!orderId || !orderDetails?.orderUserId || !orderDetails?.firmId) return;
			const req = {
				orderId: orderId,
				firmId: orderDetails.firmId,
				updateUser: orderDetails.orderUserId,
				...values,
			};
			createOrderLogCustomer(req)
				.then((res) => {
					mutate('/f-order/' + orderId);
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>留言成功!</span>
						</span>,
						`訂單時間軸已更新`,
					);
					messageFormik.resetForm();
					if (res.isSendNoticeLetter) {
						orderCustomerMessageNoticeLetter({ orderId: orderId, message: values.customerMessage })
							.then(() => {
								showNotification(
									<span className='d-flex align-items-center'>
										<Icon icon='Info' size='lg' className='me-1' />
										<span>留言成功！</span>
									</span>,
									`已將您的訊息通知商家`,
									'success',
								);
							})
							.catch((err) => {
								showNotification('錯誤', err.error.message, 'danger');
							});
					}
				})
				.catch((err) => {
					showNotification('錯誤', err.error.message, 'danger');
				});
		},
	});

	/**
	 * Common
	 */
	const { darkModeStatus } = useDarkMode();

	// function handleChangePaymentMethod() {
	// 	if (!orderDetails?.orderId || !orderDetails?.platformType || !orderDetails?.orderUserId) return;

	// 	changeFrontPaymentMethod(orderDetails.orderId, '0', orderDetails.orderUserId, orderDetails.platformType)
	// 		.then(() => {
	// 			showNotification(
	// 				<span className='d-flex align-items-center'>
	// 					<Icon icon='Info' size='lg' className='me-1' />
	// 					<span>更新成功</span>
	// 				</span>,
	// 				'已成功變更付款方式',
	// 			);
	// 		})
	// 		.catch((err) => {
	// 			console.log(err);
	// 			showNotification('error', err.error.message);
	// 		});
	// }

	function handleTriggerGtm() {
		triggerGtm(orderId || '');
	}

	function handleClickPay() {
		const req = {
			orderId: orderDetails?.orderId,
			userId: orderDetails?.orderUserId,
			firmId: orderDetails?.firmId,
			platformType: orderDetails?.platformType,
			updateUser: orderDetails?.orderUserId,
		};
		if (orderDetails?.orderDetail) {
			const orderProducts = orderDetails.orderDetail.reduce((map, current) => {
				if (window?.gtag) {
					window.gtag('event', '開始付款', {
						orderId: orderId,
						utm_source: orderDetails?.utmSource || '',
						utm_medium: orderDetails?.utmMedium || '',
						utm_campaign: orderDetails?.utmCampaign || '',
						utm_content: orderDetails?.utmContent || '',
						item_name: current.productName || '',
						price: current.productPrice || '',
						quantity: current.productQuantity || '',
					});
				}
				return map;
			}, []);
		}

		frontCreatePayment(req)
			.then((res) => {
				if (res) {
					setPaymentForm(res.result || '');
					setShowPaymentModal(true);
					setTimeout(() => {
						if (document.querySelector('#order_form')) {
							document.querySelector<HTMLFormElement>('#order_form')?.submit();
						}
						if (document.querySelector('#order_button')) {
							document.querySelector<HTMLButtonElement>('#order_button')?.click();
						}
					}, 500);
					setTimeout(() => {
						setShowPaymentModal(false);
					}, 5000);
				}
			})
			.catch((err) => {
				showNotification('付款發生錯誤', err.error.message, 'danger');
			});
	}

	function handleClickChangeInvoice() {
		setIssueInvoiceModal(true);
		issueInvoiceFormik.setValues({
			invoiceIdentifier: orderDetails?.invoiceInfo?.invoiceIdentifier || '',
			invoiceCustomerName: orderDetails?.invoiceInfo?.invoiceCustomerName || '',
			invoiceCarrierInfo: orderDetails?.invoiceInfo?.invoiceCarrierInfo || '',
			invoiceCarrierType: orderDetails?.invoiceInfo?.invoiceCarrierType || '',
			invoiceIssueType: orderDetails?.invoiceInfo?.invoiceIssueType || '1',
			invoiceTitle: orderDetails?.invoiceInfo?.invoiceTitle || '',
			invoiceType: orderDetails?.invoiceInfo?.invoiceType || '0',
			loveCode: orderDetails?.invoiceInfo?.loveCode || '',
			orderId: orderDetails?.orderId || '',
			updateUser: orderDetails?.orderUserId || '',
			firmId: orderDetails?.firmId || '',
			createLogUser: '1',
		});
	}

	function payDesc() {
		switch (orderDetails?.paymentInfo?.paymentMethod) {
			case '0':
				return '填寫匯款時間與匯款帳戶末五碼，將有專人確認付款狀態，請耐心等候';
			// return '此為ATM轉帳，轉帳資訊請至訂單頁面查詢';
			case '1':
				return '';
			case '2':
				return '';
			case '3':
				return '';
			case '4':
				return '';
			case '5':
				return '';
			case '6':
				return '';
			case '7':
				return '此付款方式將有專人聯繫，請耐心等候';
			case '8':
				return '此付款方式將有專人聯繫，請耐心等候';
			case '9':
				return '此付款方式將有專人聯繫，請耐心等候';
			case '10':
				return '此付款方式將有專人聯繫，請耐心等候';
			case '11':
				return '';
			case '12':
				return '';
			case '13':
				return '';
			default:
				return '請先選擇付款方式';
		}
	}

	function isPayDisable() {
		switch (orderDetails?.paymentInfo?.paymentMethod) {
			case '0':
				return false;
			case '1':
				return false;
			case '2':
				return false;
			case '3':
				return false;
			case '4':
				return false;
			case '5':
				return false;
			case '6':
				return false;
			case '7':
				return true;
			case '8':
				return true;
			case '9':
				return true;
			case '10':
				return true;
			case '11':
				return false;
			case '12':
				return false;
			case '13':
				return false;
			default:
				return true;
		}
	}

	function handleClickNotice() {
		setAtmPaidNotifyModal(true);
	}

	function handleClickHashNotice() {
		window.location.hash = '#';
		window.location.hash = '#notice';
		showNotification('提示', '請先閱讀須知事項並勾選同意', 'info');
	}

	function payButtonTitle() {
		const { paymentMethod } = orderDetails?.paymentInfo || {};
		switch (paymentMethod) {
			case '0':
				return '通知商家';
			case '1':
				if (orderDetails?.paymentInfo?.vAccount) {
					return '重新取號';
				}
				return '取得匯款帳號';
			default:
				return '付款';
		}
	}

	function paymentAlert(item: any) {
		if (item.chargeFeeType === '0') return '無手續費';

		return '+' + item.chargeFeeText;
	}

	function handleClickNoticeCheck() {
		if (!orderId) return;
		checkNotice(orderId).then(() => {
			mutate(`/f-order/${orderId}`);
			showNotification('成功', '您已同意下單須知', 'success');
		});
	}

	const renderPaymentInfo = (paymentMethod: string) => {
		switch (paymentMethod) {
			case '0': {
				// 銀行轉帳

				return (
					<>
						<div className='col-md-6'>
							<div className='d-flex pb-3'>
								<div className='row'>
									<div className='col d-flex align-items-center'>
										<div className='fs-4 mb-0'>銀行名稱</div>
									</div>
									<div className='col-auto text-end'>
										<div className='fw-bold fs-4 mb-0'>
											({orderDetails?.paymentInfo?.bankCode}){orderDetails?.paymentInfo?.bankName}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='d-flex pb-3'>
								<div className='row'>
									<div className='col d-flex align-items-center'>
										<div className='fs-4 mb-0'>分行名稱</div>
									</div>
									<div className='col-auto text-end'>
										<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.bankBranchName}</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='d-flex pb-3'>
								<div className='row'>
									<div className='col d-flex align-items-center'>
										<div className='fs-4 mb-0'>銀行帳號</div>
									</div>
									<div className='col-auto text-end'>
										<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.vAccount || orderDetails?.paymentInfo?.realAccount}</div>
									</div>
								</div>
							</div>
						</div>
						<div className='col-md-6'>
							<div className='d-flex pb-3'>
								<div className='row'>
									<div className='col d-flex align-items-center'>
										<div className='fs-4 mb-0'>開戶姓名</div>
									</div>
									<div className='col-auto text-end'>
										<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.bankAccountName}</div>
									</div>
								</div>
							</div>
						</div>
					</>
				);
			}
			case '1': {
				// 虛擬匯款帳戶
				return (
					orderDetails?.paymentInfo?.vAccount && (
						<>
							<div className='col-md-6'>
								<div className='d-flex pb-3'>
									<div className='row'>
										<div className='col d-flex align-items-center'>
											<div className='fs-4 mb-0'>銀行名稱</div>
										</div>
										<div className='col-auto text-end'>
											<div className='fw-bold fs-4 mb-0'>
												({orderDetails?.paymentInfo?.bankCode}){orderDetails?.paymentInfo?.bankName}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='d-flex pb-3'>
									<div className='row'>
										<div className='col d-flex align-items-center'>
											<div className='fs-4 mb-0'>銀行帳號</div>
										</div>
										<div className='col-auto text-end'>
											<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.vAccount || orderDetails?.paymentInfo?.realAccount}</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='d-flex pb-3'>
									<div className='row'>
										<div className='col d-flex align-items-center'>
											<div className='fs-4 mb-0'>取號日期</div>
										</div>
										<div className='col-auto text-end'>
											<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.getAccountDate}</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='d-flex pb-3'>
									<div className='row'>
										<div className='col d-flex align-items-center'>
											<div className='fs-4 mb-0'>付款截止日</div>
										</div>
										<div className='col-auto text-end'>
											<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.expireDate}</div>
										</div>
									</div>
								</div>
							</div>
						</>
					)
				);
			}
			case '2':
			case '3':
			case '4':
			case '5':
			case '6': {
				// 信用卡
				return null;
			}
			case '7':
			case '8':
			case '9':
			case '10': {
				//零卡分期
				return null;
			}
			default:
				break;
		}
	};

	return (
		<PageWrapper isProtected={false} title='客戶訂單'>
			<Page>
				<div className='row h-100 align-content-start'>
					<div className='col-md-12'>
						<div className='row g-4'>
							<div className='col-md-12'>
								<div className='d-flex pb-3'>
									<div className='row'>
										<div className='col d-flex align-items-center'>
											<div className='fs-1 mb-0'>訂單編號</div>
										</div>
										<div className='col-auto text-end'>
											<div className='fw-bold fs-1 mb-0'>{orderDetails?.orderNo}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-primary`}>
							<CardBody className='table-responsive' isScrollable={false}>
								<div dangerouslySetInnerHTML={{ __html: orderDetails?.orderDescription || '' }}></div>
							</CardBody>
						</Card>
					</div>
					<div className='col-md-12'>
						<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-light rounded-2 shadow-3d-primary`}>
							<CardHeader>
								<CardLabel icon='AccountBalanceWallet' iconColor='primary'>
									<CardTitle>付款資訊</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-4'>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>付款方式</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>
														{orderDetails?.paymentInfo?.paymentMethod ? PAYMENT_METHOD[orderDetails?.paymentInfo?.paymentMethod]?.name : '讀取中'}{' '}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>付款金額</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{parsePrice(orderDetails?.paymentInfo?.paymentAmount)}</div>
												</div>
											</div>
										</div>
									</div>
									<div className={orderDetails?.paymentInfo?.paymentMethod === '0' ? 'col-md-12' : 'col-md-6'}>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>付款狀態</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.paymentStatus}</div>
												</div>
											</div>
										</div>
									</div>
									{orderDetails?.paymentInfo?.paymentMethod !== '0' && (
										<div className='col-md-6'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>付款日期</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.paymentDate}</div>
													</div>
												</div>
											</div>
										</div>
									)}
									{renderPaymentInfo(orderDetails?.paymentInfo?.paymentMethod || '')}
								</div>
							</CardBody>
							{orderDetails?.paymentInfo?.paymentStatus !== '已付款' && (
								<CardBody>
									<div className='row g-4'>
										<div className='col-6'>
											<Button
												className='w-100'
												color='light'
												icon='CreditCard'
												onClick={() => {
													setChangePaymentMethodModal(true);
													formik.setFieldValue('paymentMethod', orderDetails?.paymentInfo?.paymentMethod || '0');
												}}>
												更改付款方式
											</Button>
										</div>
										<Popovers desc={payDesc() || ''} trigger='hover'>
											<div className='col-6'>
												<Button
													className='w-100'
													color='primary'
													icon={orderDetails?.paymentInfo?.paymentMethod === '0' ? 'Notifications' : 'CreditCard'}
													onClick={
														orderDetails?.noticeValue === 'true'
															? orderDetails?.paymentInfo?.paymentMethod === '0'
																? handleClickNotice
																: handleClickPay
															: handleClickHashNotice
													}
													isDisable={isPayDisable()}>
													{payButtonTitle()}
												</Button>
											</div>
										</Popovers>
									</div>
								</CardBody>
							)}
						</Card>
						<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-light rounded-2 shadow-3d-light`}>
							<CardHeader>
								<CardLabel icon='Inventory' iconColor='danger'>
									<CardTitle>產品</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable={false}>
								<table className='table table-modern'>
									<thead>
										<tr>
											<th className='text-center'>圖片</th>
											<th>品項</th>
											<th className='text-center'>單價</th>
											<th className='text-center'>數量</th>
											<th className='text-end'>金額</th>
										</tr>
									</thead>
									<tbody>
										{orderDetails?.orderDetail?.map((item, index) => (
											<tr key={index}>
												<td width='60px' align='center'>
													{item?.productImgUrl && (
														<img
															alt={item.productName}
															src={item.productImgUrl}
															style={{
																width: 60,
																height: 60,
															}}
														/>
													)}
												</td>
												<td>{item.productName}</td>
												<td className='text-center'>{parsePrice(item?.productPrice)}</td>
												<td className='text-center'>{parsePrice(item?.productQuantity)}</td>
												<td className='text-end'>{parsePrice(item?.productPrice && item?.productQuantity && item.productPrice * item.productQuantity)}</td>
											</tr>
										))}
										<tr>
											<td colSpan={4} align='right'>
												小計:
											</td>
											<td className='text-end'>${parsePrice(orderDetails?.orderSubtotal)}</td>
										</tr>
										<tr>
											<td colSpan={4} align='right'>
												金流:
											</td>
											<td className='text-end'>${parsePrice(orderDetails?.cashFee)}</td>
										</tr>
										<tr>
											<td colSpan={4} align='right'>
												物流:
											</td>
											<td className='text-end'>${parsePrice(orderDetails?.deliveryFee)}</td>
										</tr>
										{orderDetails?.paymentInfo?.refundText && (
											<tr>
												<td colSpan={4} align='right'>
													{orderDetails?.paymentInfo?.refundText}
												</td>
												<td className='text-end'>${parsePrice(orderDetails?.paymentInfo?.refundAmt)}</td>
											</tr>
										)}
										<tr>
											<td colSpan={4} align='right'>
												總計:
											</td>
											<td className='text-end'>
												<span className='text-primary'>${parsePrice(orderDetails?.orderTotal)}</span>
											</td>
										</tr>
									</tbody>
								</table>
							</CardBody>
							<CardFooter>
								<CardLabel style={{ whiteSpace: 'pre-wrap' }}>訂單備註： {orderDetails?.orderMemoCustomer || ''}</CardLabel>
							</CardFooter>
						</Card>
					</div>
					<div className='col-md-12 shadow-3d-container'>
						<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-warning rounded-2 shadow-3d-warning `}>
							<CardHeader className='bg-transparent'>
								<CardLabel icon='LocalShipping' iconColor='warning'>
									<CardTitle>運送資訊</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row justify-content-center'>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>姓名</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.name}</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>電話</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.phone}</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>信箱</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.email}</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>地址</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.address}</div>
												</div>
											</div>
										</div>
									</div>
									{orderDetails?.deliveryType === '6' && (
										<div className={`${orderDetails?.deliveryInfo?.sevenStoreId ? 'col-md-6' : 'col-md-12'}`}>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>7-11店家</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>
															{/* <Button
																className='w-full'
																icon='Store'
																size='sm'
																isLink
																onClick={() => window.open(`https://cashflow.ramatetech.com/seven/select/${orderId}`, '_blank')}>
																點我選擇7-11店家
															</Button> */}
															<a href={`https://cashflow.ramatetech.com/seven/select/${orderId}`} target='_blank' rel='noreferrer'>
																選擇7-11店家
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									{orderDetails?.deliveryType === '6' && orderDetails?.deliveryInfo?.sevenStoreId && (
										<>
											<div className='col-md-6'>
												<div className='d-flex pb-3'>
													<div className='row'>
														<div className='col d-flex align-items-center'>
															<div className='fs-4 mb-0'>門市代號</div>
														</div>
														<div className='col-auto text-end'>
															<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.sevenStoreId}</div>
														</div>
													</div>
												</div>
											</div>
											<div className='col-md-6'>
												<div className='d-flex pb-3'>
													<div className='row'>
														<div className='col d-flex align-items-center'>
															<div className='fs-4 mb-0'>門市名稱</div>
														</div>
														<div className='col-auto text-end'>
															<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.sevenStoreName}</div>
														</div>
													</div>
												</div>
											</div>
											<div className='col-md-6'>
												<div className='d-flex pb-3'>
													<div className='row'>
														<div className='col d-flex align-items-center'>
															<div className='fs-4 mb-0'>門市地址</div>
														</div>
														<div className='col-auto text-end'>
															<div className='fw-bold fs-4 mb-0'>{orderDetails?.deliveryInfo?.sevenStoreAddress}</div>
														</div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>
							</CardBody>
						</Card>
						<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-success rounded-2 shadow-3d-success`}>
							<CardHeader className='bg-transparent'>
								<CardLabel icon='Receipt' iconColor='success'>
									<CardTitle>發票資訊</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row justify-content-start'>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>發票狀態</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>
														{orderDetails?.invoiceInfo?.invoiceStatus && INVOICE_STATUS[orderDetails?.invoiceInfo?.invoiceStatus].name}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='col-md-6'>
										<div className='d-flex pb-3'>
											<div className='row'>
												<div className='col d-flex align-items-center'>
													<div className='fs-4 mb-0'>發票類型</div>
												</div>
												<div className='col-auto text-end'>
													<div className='fw-bold fs-4 mb-0'>
														{orderDetails?.invoiceInfo?.invoiceType && INVOICE_TYPE[orderDetails?.invoiceInfo?.invoiceType]}
													</div>
												</div>
											</div>
										</div>
									</div>

									{(orderDetails?.invoiceInfo?.invoiceNo || orderDetails?.invoiceInfo?.loveCode) && (
										<div className='col-md-6'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>
															{orderDetails?.invoiceInfo?.invoiceNo && '發票號碼'}
															{orderDetails?.invoiceInfo?.loveCode && '愛心碼'}
														</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>{orderDetails?.invoiceInfo?.invoiceNo || orderDetails?.invoiceInfo?.loveCode}</div>
													</div>
												</div>
											</div>
										</div>
									)}

									{(orderDetails?.invoiceInfo?.invoiceCarrierInfo || orderDetails?.invoiceInfo?.invoiceIdentifier) && (
										<div className='col-md-6'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>
															{/* {orderDetails?.invoiceInfo?.invoiceCarrierInfo && '載具'} */}
															{orderDetails?.invoiceInfo?.invoiceCarrierInfo && INVOICE_CARRIER_TYPE[orderDetails?.invoiceInfo?.invoiceCarrierType || '0']}
															{orderDetails?.invoiceInfo?.invoiceIdentifier && '統一編號'}
														</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>
															{orderDetails?.invoiceInfo?.invoiceCarrierInfo || orderDetails?.invoiceInfo?.invoiceIdentifier}
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									{orderDetails?.invoiceInfo?.invoiceTitle && (
										<div className='col-md-6'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>抬頭</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>{orderDetails?.invoiceInfo?.invoiceTitle}</div>
													</div>
												</div>
											</div>
										</div>
									)}
									{!orderDetails?.invoiceInfo?.lockInvoiceStatus && (
										<div className='col-md-6'>
											{orderDetails?.invoiceInfo?.invoiceStatus !== '1' && (
												<Button className='w-50' color='info' icon='Receipt' onClick={handleClickChangeInvoice}>
													更改發票資訊
												</Button>
											)}
										</div>
									)}
								</div>
							</CardBody>
						</Card>
					</div>
					{orderDetails?.entityTrackLogisticsInfo?.map((item) => (
						<div className='col-md-6' key={item.logisticsId}>
							<Card className={`bg-l${darkModeStatus ? 'o25' : '10'}-secondary rounded-2 shadow-3d-secondary`}>
								<CardHeader className='bg-transparent'>
									<CardLabel icon='GpsFixed' iconColor='secondary'>
										<CardTitle>物流追蹤</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row justify-content-center'>
										<div className='col-md-12'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>物流廠商</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>
															<LinkText textDecoration='underline' onClick={() => window.open(`${item?.logisticsUrl}`)} darkModeStatus={darkModeStatus}>
																{item?.logisticsName}
															</LinkText>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>追蹤號碼</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>{item?.trackNo}</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-md-12'>
											<div className='d-flex pb-3'>
												<div className='row'>
													<div className='col d-flex align-items-center'>
														<div className='fs-4 mb-0'>出貨日期</div>
													</div>
													<div className='col-auto text-end'>
														<div className='fw-bold fs-4 mb-0'>{item?.shipDate || '尚未寄出'}</div>
													</div>
												</div>
											</div>
										</div>

										{item?.deliveryDate && (
											<div className='col-md-12'>
												<div className='d-flex pb-3'>
													<div className='row'>
														<div className='col d-flex align-items-center'>
															<div className='fs-4 mb-0'>抵達日期</div>
														</div>
														<div className='col-auto text-end'>
															<div className='fw-bold fs-4 mb-0'>{item?.deliveryDate}</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>
								</CardBody>
							</Card>
						</div>
					))}

					<div id='notice'></div>
					{orderDetails?.noticeDescription && (
						<div className='col-md-12'>
							<Card stretch>
								<CardHeader>
									<CardLabel icon='Notifications' iconColor='warning'>
										<CardTitle tag='h4' className='h5'>
											須知事項
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div
										style={{
											whiteSpace: 'pre-line',
										}}>
										{orderDetails?.noticeDescription}
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px' }}>
										<label>
											<input
												type='checkbox'
												checked={orderDetails?.noticeValue === 'true' ? true : false}
												disabled={orderDetails?.noticeValue === 'true'}
												onChange={() => handleClickNoticeCheck()}
											/>{' '}
											{orderDetails?.noticeName} {orderDetails?.noticeValue === 'true' ? '(您已同意)' : ''}
										</label>
									</div>
								</CardBody>
							</Card>
						</div>
					)}
					<div className='col-md-12'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Timer' iconColor='warning'>
									<CardTitle tag='h4' className='h5'>
										訂單時間軸
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<Timeline>
									<TimelineItem label={''} color='success'>
										<Card className='light' style={screenCheck ? { width: '500px' } : { width: '250px' }}>
											<CardHeader className='light' borderColor='light' borderSize={1}>
												<CardLabel>
													<CardTitle>給商家的留言</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													<div className='col-12'>
														<Textarea
															name='customerMessage'
															placeholder='請輸入留言'
															value={messageFormik.values.customerMessage}
															onChange={messageFormik.handleChange}></Textarea>
													</div>
													<div className='col-12' style={{ paddingTop: 10, display: 'flex', flexDirection: 'row-reverse' }}>
														<Button icon='Add' color='light' onClick={messageFormik.handleSubmit}>
															新增
														</Button>
													</div>
												</div>
											</CardBody>
										</Card>
									</TimelineItem>
									{orderDetails?.orderLogInfo?.map((item, index) => (
										<TimelineItem label={''} color='success' key={item.orderLogId}>
											<Card className='light' style={screenCheck ? { width: '500px' } : { width: '250px' }}>
												<CardHeader className='light' borderSize={3}>
													<CardLabel>
														<CardTitle>{item.orderLogType}</CardTitle>
														<CardLabel>{item.orderLogTime}</CardLabel>
													</CardLabel>
												</CardHeader>
												<CardBody>
													<Linkify>
														<div className='light' style={{ whiteSpace: 'pre-line' }}>
															{item.orderLogText}
														</div>
													</Linkify>
												</CardBody>
											</Card>
										</TimelineItem>
									))}
								</Timeline>
							</CardBody>
						</Card>
					</div>
				</div>

				<Modal id='ChangePaymentMethodModal' isOpen={changePaymentMethodModal} setIsOpen={setChangePaymentMethodModal} isCentered>
					<ModalHeader>
						<ModalTitle id='title'>更改付款方式</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<p>您可以更改付款方式，更改後，依據商家原始設定，金額可能會變動。</p>
						<div className='row g-4'>
							{avaliablePaymentMethod?.payMethod ? (
								<FormGroup className='col-md-6'>
									<ChecksGroup>
										{avaliablePaymentMethod.payMethod.map((item) => (
											<div key={item.paymentMethod} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
												<label style={{ display: 'flex', alignItems: 'center' }}>
													<>
														<input
															type='radio'
															name='paymentMethod'
															id={item.paymentMethod}
															value={item.paymentMethod}
															onChange={formik.handleChange}
															checked={formik.values.paymentMethod === item.paymentMethod}
															style={{ marginRight: '5px' }}
														/>
														{item.paymentMethodName} <span style={{ color: 'red', marginLeft: '10px' }}>{paymentAlert(item)}</span>
													</>
												</label>
											</div>
										))}
										{/* <div key='1234' style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
											<label style={{ display: 'flex', alignItems: 'center' }}>
												<input type='radio' name='paymentMethod' id='1234' value='2' style={{ marginRight: '5px' }} />
												銀角零卡 (此付款方式將有專人聯繫，請耐心等候)
											</label>
										</div> */}
										{/* {avaliablePaymentMethod.payMethod &&
											Object.values(PAYMENT_METHOD).map((item) => (
												<Checks
													type='checkbox'
													name='paymentMethod'
													key={item.paymentMethod}
													id={item.paymentMethod}
													value={item.paymentMethod}
													label={item.name}
													onChange={formik.handleChange}
													checked={formik.values.paymentMethod == item.paymentMethod}
												/>
											))} */}
									</ChecksGroup>
								</FormGroup>
							) : (
								<span>讀取中，請稍候...</span>
							)}
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='row g-4'>
							<div className='col'>
								<Button color='primary' onClick={formik.handleSubmit}>
									儲存
								</Button>
							</div>
							<div className='col'>
								<Button color='light' onClick={() => setChangePaymentMethodModal(false)}>
									取消
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>
				<Modal id='paymentFormModal' isOpen={showPaymentModal} setIsOpen={setShowPaymentModal} isCentered>
					<ModalHeader>{/* */} </ModalHeader>
					<ModalBody>
						<div dangerouslySetInnerHTML={{ __html: paymentForm }}></div>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
							<Spinner color='info'></Spinner>
							<span style={{ fontSize: '24px', marginLeft: '10px' }}>即將跳轉至付款頁...</span>
						</div>
					</ModalBody>
					<ModalFooter>{/* */} </ModalFooter>
				</Modal>
				<Modal id='issueInvoiceModal' isOpen={issueInvoiceModal} setIsOpen={setIssueInvoiceModal} isCentered>
					<ModalHeader>
						<ModalTitle id='title'>編輯待開立發票資訊</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='row g-4'>
							<div className='col-lg-12'>
								<FormGroup id='invoiceCustomerName' label='受買人姓名' isFloating>
									<Input
										placeholder='受買人姓名'
										name='invoiceCustomerName'
										type='text'
										value={issueInvoiceFormik.values.invoiceCustomerName}
										onChange={issueInvoiceFormik.handleChange}
									/>
								</FormGroup>
							</div>
							<div className='col-lg-12'>
								<FormGroup id='invoiceIssueType' label='類型' isFloating>
									<Select
										id='invoiceIssueType'
										name='invoiceIssueType'
										placeholder='請選擇'
										ariaLabel='invoiceIssueType'
										value={issueInvoiceFormik.values.invoiceIssueType}
										onChange={issueInvoiceFormik.handleChange}>
										{avaliableInvoiceMethod?.invoicePaper && (
											<Option key='0' value='0'>
												紙本發票
											</Option>
										)}

										{avaliableInvoiceMethod?.invoiceThirdParty && (
											<Option key='1' value='1'>
												電子發票
											</Option>
										)}
									</Select>
								</FormGroup>
							</div>
							<div className='col-lg-12'>
								<FormGroup id='invoiceType' label='發票類型' isFloating>
									<Select
										id='invoiceType'
										name='invoiceType'
										placeholder='請選擇'
										ariaLabel='invoiceType'
										value={issueInvoiceFormik.values.invoiceType}
										onChange={issueInvoiceFormik.handleChange}>
										{(avaliableInvoiceMethod?.typeBarcode ||
											avaliableInvoiceMethod?.typeCertificate ||
											avaliableInvoiceMethod?.typeMember ||
											avaliableInvoiceMethod?.invoicePaper) && (
											<Option key='0' value='0'>
												個人
											</Option>
										)}
										{(avaliableInvoiceMethod?.typeCompany || avaliableInvoiceMethod?.invoicePaper) && (
											<Option key='1' value='1'>
												公司
											</Option>
										)}
										{issueInvoiceFormik.values.invoiceIssueType !== '0' && avaliableInvoiceMethod?.typeDonation && (
											<Option key='2' value='2'>
												捐贈
											</Option>
										)}
									</Select>
								</FormGroup>
							</div>

							{issueInvoiceFormik.values.invoiceType === '1' ? (
								<>
									<div className='col-lg-12'>
										<FormGroup id='invoiceTitle' label='發票抬頭' isFloating>
											<Input
												placeholder='發票抬頭'
												name='invoiceTitle'
												type='text'
												value={issueInvoiceFormik.values.invoiceTitle}
												onChange={issueInvoiceFormik.handleChange}
											/>
										</FormGroup>
									</div>
									<div className='col-lg-12'>
										<FormGroup id='invoiceIdentifier' label='統一編號' isFloating>
											<Input
												placeholder='統一編號'
												name='invoiceIdentifier'
												type='text'
												value={issueInvoiceFormik.values.invoiceIdentifier}
												onChange={issueInvoiceFormik.handleChange}
											/>
										</FormGroup>
									</div>
									{/* <div className='col-lg-12'>
										<FormGroup id='invoiceTitle' label='受買人地址' isFloating formText="第三方必填，顧客無提供，建議填寫公司地址">
											<Input
												placeholder='受買人地址'
												name='invoiceTitle'
												type='text'
												value={issueInvoiceFormik.values.}
												onChange={issueInvoiceFormik.handleChange}
											/>
										</FormGroup>
									</div> */}
								</>
							) : issueInvoiceFormik.values.invoiceType === '2' ? (
								<div className='col-lg-12'>
									<FormGroup id='loveCode' label='捐贈至機構愛心碼' isFloating>
										{/* <Input
											placeholder='捐贈至機構愛心碼'
											name='loveCode'
											type='text'
											value={issueInvoiceFormik.values.loveCode}
											onChange={issueInvoiceFormik.handleChange}
										/> */}
										<Select
											id='loveCode'
											name='loveCode'
											placeholder='請選擇愛心機構'
											ariaLabel='loveCode'
											value={issueInvoiceFormik.values.loveCode}
											onChange={issueInvoiceFormik.handleChange}>
											{defaultLoveCode.map((item, index) => (
												<Option key={item.loveCode} value={item.loveCode}>
													{item.orgName}
												</Option>
											))}
										</Select>
									</FormGroup>
								</div>
							) : issueInvoiceFormik.values.invoiceType === '0' && issueInvoiceFormik.values.invoiceIssueType !== '0' ? (
								<>
									<div className='col-lg-12'>
										<FormGroup id='invoiceCarrierType' label={issueInvoiceFormik.values.invoiceCarrierType === '' ? '' : '載具類別'} isFloating>
											<Select
												id='invoiceCarrierType'
												name='invoiceCarrierType'
												placeholder='請選擇'
												ariaLabel='invoiceCarrierType'
												value={issueInvoiceFormik.values.invoiceCarrierType}
												onChange={issueInvoiceFormik.handleChange}>
												<Option key='0' value=''>
													無載具
												</Option>
												<Option key='1' value='1'>
													電子發票載具
												</Option>
												<Option key='2' value='2'>
													自然人憑證
												</Option>
												<Option key='3' value='3'>
													手機條碼
												</Option>
											</Select>
										</FormGroup>
									</div>
									<div className='col-lg-12'>
										<FormGroup
											id='invoiceCarrierInfo'
											label={
												issueInvoiceFormik.values.invoiceCarrierType === '1'
													? '電子發票載具'
													: issueInvoiceFormik.values.invoiceCarrierType === '2'
													? '自然人憑證編號'
													: issueInvoiceFormik.values.invoiceCarrierType === '3'
													? '手機條碼載具編號'
													: ''
											}
											isFloating
											formText={
												issueInvoiceFormik.values.invoiceCarrierType === '1'
													? '電子發票將寄送至Email'
													: issueInvoiceFormik.values.invoiceCarrierType === '2'
													? '請輸入自然人憑證右下角，2碼英文字母+14碼數字之編號。'
													: issueInvoiceFormik.values.invoiceCarrierType === '3'
													? '請輸入/開頭，共8碼之手機條碼載具編號'
													: '選擇無載具，電子發票會自動寄送至您所填寫的信箱'
											}>
											<>
												{issueInvoiceFormik.values.invoiceCarrierType === '' ? null : (
													<Input
														disabled={issueInvoiceFormik.values.invoiceCarrierType === ''}
														name='invoiceCarrierInfo'
														type='text'
														value={issueInvoiceFormik.values.invoiceCarrierInfo}
														onChange={issueInvoiceFormik.handleChange}
													/>
												)}
											</>
										</FormGroup>
									</div>
								</>
							) : null}
						</div>
					</ModalBody>
					<ModalFooter>
						<div className='row g-4'>
							<div className='col'>
								<Button color='primary' onClick={() => issueInvoiceFormik.handleSubmit()}>
									儲存
								</Button>
							</div>
							<div className='col'>
								<Button color='light' onClick={() => setIssueInvoiceModal(false)}>
									取消
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>

				<Modal id='atmPaidNotifyModal' isOpen={atmPaidNotifyModal} setIsOpen={setAtmPaidNotifyModal} isCentered>
					<ModalHeader>
						<ModalTitle id='title'>通知商家</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<form noValidate>
							<div className='row g-4'>
								<div className='col-md-12'>
									<div className='d-flex'>
										<div className='row'>
											<div className='col d-flex align-items-center'>
												<div className='fs-4 mb-0'>銀行名稱</div>
											</div>
											<div className='col-auto text-end'>
												<div className='fw-bold fs-4 mb-0'>
													({orderDetails?.paymentInfo?.bankCode}){orderDetails?.paymentInfo?.bankName}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-md-12'>
									<div className='d-flex'>
										<div className='row'>
											<div className='col d-flex align-items-center'>
												<div className='fs-4 mb-0'>分行名稱</div>
											</div>
											<div className='col-auto text-end'>
												<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.bankBranchName}</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-md-12'>
									<div className='d-flex'>
										<div className='row'>
											<div className='col d-flex align-items-center'>
												<div className='fs-4 mb-0'>銀行帳號</div>
											</div>
											<div className='col-auto text-end'>
												<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.vAccount || orderDetails?.paymentInfo?.realAccount}</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-md-12'>
									<div className='d-flex'>
										<div className='row'>
											<div className='col d-flex align-items-center'>
												<div className='fs-4 mb-0'>開戶姓名</div>
											</div>
											<div className='col-auto text-end'>
												<div className='fw-bold fs-4 mb-0'>{orderDetails?.paymentInfo?.bankAccountName}</div>
											</div>
										</div>
									</div>
								</div>
								<div className='col-lg-12'>
									<FormGroup
										id='paymentDate'
										label='匯款時間'
										isFloating
										formText={
											!atmPaidNotifyFormik.errors.paymentDate &&
											atmPaidNotifyFormik.values.paymentDate !== '' &&
											`已選擇 ${moment(atmPaidNotifyFormik.values.paymentDate).format('YYYY-MM-DD HH:mm')}`
										}>
										<Input
											placeholder='匯款時間'
											name='paymentDate'
											type='datetime-local'
											max='2099-12-31T23:59'
											min='2000-01-01T00:00'
											required
											value={atmPaidNotifyFormik.values.paymentDate}
											onChange={atmPaidNotifyFormik.handleChange}
											onBlur={atmPaidNotifyFormik.handleBlur}
											isValid={atmPaidNotifyFormik.isValid}
											invalidFeedback={atmPaidNotifyFormik.errors.paymentDate}
											isTouched={atmPaidNotifyFormik.touched.paymentDate}
											// format='yyyy-MM-dd HH:mm'
										/>
									</FormGroup>
								</div>
								<div className='col-lg-12'>
									<FormGroup id='accountLastFiveCode' label='匯款帳戶末五碼' isFloating>
										<Input
											placeholder='匯款帳戶末五碼'
											name='accountLastFiveCode'
											type='text'
											required
											value={atmPaidNotifyFormik.values.accountLastFiveCode}
											onChange={atmPaidNotifyFormik.handleChange}
											onBlur={atmPaidNotifyFormik.handleBlur}
											isValid={atmPaidNotifyFormik.isValid}
											invalidFeedback={atmPaidNotifyFormik.errors.accountLastFiveCode}
											isTouched={atmPaidNotifyFormik.touched.accountLastFiveCode}
										/>
									</FormGroup>
								</div>
							</div>
						</form>
					</ModalBody>
					<ModalFooter>
						<div className='row g-4'>
							<div className='col'>
								<Button color='primary' onClick={() => atmPaidNotifyFormik.handleSubmit()}>
									送出
								</Button>
							</div>
							<div className='col'>
								<Button color='light' onClick={() => setAtmPaidNotifyModal(false)}>
									取消
								</Button>
							</div>
						</div>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default FrontendOrderPage;
