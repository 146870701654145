import React from 'react';
import PropTypes from 'prop-types';

interface LinkTextStruct {
	onClick?: () => void;
	darkModeStatus?: boolean;
	children?: React.ReactNode;
	textDecoration?: string;
}

const LinkText = ({ children, onClick, darkModeStatus, textDecoration }: LinkTextStruct) => {
	return (
		<span
			style={{
				textDecoration: textDecoration,
				cursor: 'pointer',
				color: darkModeStatus ? '#ff00b8' : 'blue',
			}}
			onClick={onClick}>
			{children}
		</span>
	);
};

LinkText.defaultProps = {
	onClick: () => {},
	darkModeStatus: false,
	textDecoration: false,
};

export default LinkText;
