import React, { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/ramateVerify';
import { useDispatch } from 'react-redux';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import Logo from './logo_login.svg';

const AdminLogin = () => {
	const { setUser } = useContext(AuthContext);
	const { t } = useTranslation(['auth']);
	const dispatch = useDispatch();
	const { darkModeStatus } = useDarkMode();

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/ramate-verify'), [navigate]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = '請輸入帳號';
			}

			if (!values.loginPassword) {
				errors.loginPassword = '請輸入密碼';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			const deviceId = await getCurrentBrowserFingerPrint();
			const account = {
				account: values.loginUsername,
				password: values.loginPassword,
				platformType: '0',
				deviceId: deviceId.toString(),
			};
			API.adminLogin(account)
				.then((res) => {
					if (setUser) {
						const storageData = {
							firmId: '',
							userId: res.ramateAccountId,
							token: res.ramateAccountToken,
							deviceId: account.deviceId,
						};
						setUser(storageData);
					}

					dispatch({ type: 'FIRM_ADMIN_LOGIN', payload: { ...res, deviceId: account.deviceId } });
					handleOnClick();
				})
				.catch((err) => {
					formik.setFieldError('loginPassword', err.error.message);
				});
		},
	});
	const LoginHeader = () => {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>{t('Login Title')} Admin</div>
				<div className='text-center h4 text-muted mb-5'>{t('Login Content')}</div>
			</>
		);
	};

	return (
		<PageWrapper isProtected={false} title={t('Login')} className={classNames('bg-warning')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames('text-decoration-none  fw-bold display-2', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}>
										{/* <Logo width={200} /> */}
										<img src={Logo} alt='logo' width={150} />
									</Link>
								</div>

								<LoginHeader />

								{/* <Alert isLight icon='Lock' isDismissible>
									<div className='row'>
										<div className='col-12'>
											<strong>Username:</strong> service
										</div>
										<div className='col-12'>
											<strong>Password:</strong> 1234
										</div>
									</div>
								</Alert> */}
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup id='loginUsername' isFloating label={t('username')}>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={formik.errors.loginUsername}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
										<FormGroup className='mt-3' id='loginPassword' isFloating label={t('password')}>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button color='warning' type='submit' className='w-100 py-3' onClick={formik.handleSubmit}>
											{t('Login')}
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a href='/' className={classNames('link-dark text-decoration-none me-3')}>
								{t('Privacy policy')}
							</a>
							<a href='/' className={classNames('link-light text-decoration-none link-dark')}>
								{t('Terms of use')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AdminLogin;
