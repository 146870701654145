import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import { Provider } from 'react-redux';
import store from './redux/configureStore';
import './i18n';
import { SWRConfig } from 'swr';
import showNotification from './components/extras/showNotification';
// const store = createStore(reducers, applyMiddleware(thunk));

const children = (
	<Provider store={store}>
		<AuthContextProvider>
			<ThemeContextProvider>
				<SWRConfig
					value={{
						shouldRetryOnError: true,
						focusThrottleInterval: 3000,
						onError: (err, key, config) => {
							if (err.error.code === 2) {
								showNotification('權限不足', '權限不足，請聯繫您的帳號管理者', 'danger');
							}
							if (err.status === 503 || err.error.code === 14) {
								showNotification('系統發生錯誤', '系統發生錯誤，請嘗試重新整理頁面', 'danger');
							}
						},
						onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
							if (retryCount >= 5) {
								store.dispatch({ type: 'FIRM_LOGOUT' });
								return;
							}
							if (error.error.code === 2) return;
							if (error.error.code === 12) {
								// showNotification('err', `retry count: ${retryCount} , error code : ${error.error.code}`, 'success');

								if (store.getState().user.userInfo.authorizationToken !== '') {
									revalidate({ retryCount: retryCount + 4 });
								} else {
									return;
								}
							}
							if (error.status === 503 || error.error.code === 14) {
								revalidate({ retryCount: retryCount });
							}
						},
						errorRetryInterval: 2000,
						errorRetryCount: 3,
						// fetcher: (url: string) =>
						// 	store.getState().user.firmInfo.firmId !== '' &&
						// 	store.getState().user.userInfo.userId !== '' &&
						// 	fetch(url, { headers: { Authorization: 'Bearer ' + '282SCV23' } }).then((res) => res.json()),
					}}>
					<Router>
						{/* <React.StrictMode> */}
						<App />
						{/* </React.StrictMode> */}
					</Router>
				</SWRConfig>
			</ThemeContextProvider>
		</AuthContextProvider>
	</Provider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
