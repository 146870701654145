import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Button from '../../components/bootstrap/Button';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import CommonDesc from '../../common/other/CommonDesc';
import Icon from '../../components/icon/Icon';
import showNotification from '../../components/extras/showNotification';
import useSWR, { mutate } from 'swr';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { getAdminInfo, loadPointSafetyStock, updateAdminInfo, updatePointSafetyStock } from '../../api/ramateVerify';
import { useNavigate } from 'react-router-dom';

type TTabs = 'Account Details' | 'Address' | 'My Wallet' | 'Third Party' | 'Personal Setting';
interface ITabs {
	[key: string]: TTabs;
}
const AdminAlerts = () => {
	const TABS: ITabs = {
		ACCOUNT_DETAIL: 'Account Details',
		ADDRESS: 'Address',
		MY_WALLET: 'My Wallet',
		THIRD_PARTY: 'Third Party',
		PERSONAL_SETTING: 'Personal Setting',
	};
	const [activeTab, setActiveTab] = useState<TTabs>(TABS.PERSONAL_SETTING);
	const { userId } = useSelector((state: RootState) => state.user.userInfo);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data } = useSWR(userId && '/firm/pointSafetyStock', () => loadPointSafetyStock(userId));

	// useEffect(() => {
	// 	formik.setValues({
	// 		ramateAccountName: adminInfo?.ramateAccountName || '',
	// 	});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [adminInfo]);

	const formik = useFormik({
		initialValues: {
			pointSafetyStock: data?.pointSafetyStock || 0,
		},
		onSubmit: (values) => {
			updatePointSafetyStock(data?.firmId || '', values.pointSafetyStock)
				.then((res) => {
					mutate('/firmInfo');
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>更新個人資訊成功!</span>
						</span>,
						`資料已更新`,
						'success',
					);
				})
				.catch((err) => {
					showNotification('錯誤', err.error.message, 'danger');
				});
		},
	});

	return (
		<PageWrapper title='提醒設定'>
			<Page>
				<div className='row h-100'>
					<div className='col-xl-3 col-lg-4 col-md-6'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='WarningAmber' iconColor='info'>
									<CardTitle>提醒設定</CardTitle>
									<CardSubTitle>Alert Settings</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Mail'
											color='info'
											className='w-100 p-3'
											isLight={TABS.PERSONAL_SETTING !== activeTab}
											onClick={() => setActiveTab(TABS.PERSONAL_SETTING)}>
											簡訊安全庫存量
										</Button>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<div></div>
								{/* <CardFooterLeft className='w-100'>
									<Button icon='Delete' color='danger' isLight className='w-100 p-3'>
										刪除商家
									</Button>
								</CardFooterLeft> */}
							</CardFooter>
						</Card>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-6'>
						{TABS.PERSONAL_SETTING === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Person' iconColor='info'>
										<CardTitle>簡訊安全庫存量設定</CardTitle>
										<CardSubTitle>當廠商所擁有的簡訊安全庫存量低於所設定值時收到通知</CardSubTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='pb-0' isScrollable>
									<div className='row g-4'>
										<div className='col-md-6'>
											<FormGroup id='pointSafetyStock' label='預設點數安全量' isFloating>
												<Input placeholder='預設點數安全量' onChange={formik.handleChange} value={formik.values.pointSafetyStock} />
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline isDisable={!formik.isValid && !!formik.submitCount} onClick={formik.handleSubmit}>
											儲存
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default AdminAlerts;
